import { urls } from '@abqm/services';

export default [
  {
    id: 1,
    title: 'Home',
    target: '_self',
    url: `${urls.observatoryURL}`,
  },
  {
    id: 745,
    title: 'Institucional',
    target: '_self',
    url: `${urls.observatoryURL}/institucional`,
  },
  {
    id: 746,
    title: 'Infográfico',
    target: '_self',
    url: `${urls.observatoryURL}/infografico`,
  },
  {
    id: 747,
    title: 'Legislação',
    target: '_self',
    url: `${urls.observatoryURL}/legislacao`,
  },
  {
    id: 748,
    title: 'Publicações',
    target: '_self',
    url: `${urls.observatoryURL}/publicacoes`,
  },
  {
    id: 749,
    title: 'Campeonatos',
    target: '_self',
    url: `${urls.observatoryURL}/campeonatos`,
  },
  {
    id: 750,
    title: 'Editais',
    target: '_self',
    url: `${urls.observatoryURL}/editais`,
  },
  {
    id: 751,
    title: 'Imprensa',
    target: '_self',
    url: `${urls.observatoryURL}/imprensa`,
  },
  {
    id: 752,
    title: 'Contato',
    target: 'blank',
    url: `https://abqm.net.br/abqm_site/pt/contacts/attendance/`,
  },
];
