import React from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';
import { Link } from 'react-router-dom';
import { urls } from '@abqm/services';

import ContactCard from './components/ContactCard';

import { Container, Socials } from './styles';

function Footer({ isMobile }) {
    return ( <
        Container >
        <
        div className = "logos" >
        <
        img alt = "Logo ABQM"
        className = "abqm-logo"
        src = { require('@abqm/theme/assets/images/logos/logo-abqm.svg') }
        />

        <
        img alt = "Logo AQHA"
        className = "aqha-logo"
        src = { require('@abqm/theme/assets/images/logos/logo-aqha.svg') }
        /> < /
        div >

        <
        div className = "contact" > {!isMobile && ( <
                >
                <
                ContactCard iconName = "search"
                title = "Transparência" >
                <
                a href = "https://abqm.net.br/abqm_site/pt/canal-etica-e-ouvidoria" >
                Portal da transparência <
                /a>

                <
                Link to = "institucional/certificado-iso-9001" >
                Certificado ISO 9001 <
                /Link>

                <
                Link to = "institucional/missao-visao-e-valores-1998" >
                Missão, visão e valores <
                /Link>

                <
                Link to = "institucional/politica-de-privacidade" >
                Politica de privacidade <
                /Link>

                <
                Link to = "institucional/termo-de-aceite" > Termo de aceite < /Link> < /
                ContactCard >

                <
                ContactCard iconName = "phone"
                title = "Telefone" >
                <
                p > +55(11) 3864 - 0800 < /p> <
                p > De segunda a sexta < /p> <
                p > das 9 h às 17 h < /p> <
                p / >
                <
                a className = "link"
                href = {`${urls.portalURL}/servicos/atendimento-inteligente`} >
                <
                b > Contato < /b> < /
                a > <
                /ContactCard>

                <
                ContactCard iconName = "whatsappNew"
                title = "Notícias ABQM" >
                <
                p > Fique por dentro de tudo o < /p> <
                p > que acontece na ABQM < /p> <
                a className = "link-wpp"
                href = "https://chat.whatsapp.com/HPqSZeaOUaJKV4cO4Ws8BX"
                target = "_blank"
                rel = "noopener noreferrer" >
                Clique aqui <
                /a> <
                p > e entre para o nosso < /p> <
                p > grupo de notícias < /p> < /
                ContactCard >

                {
                    /* <ContactCard iconName="house" title="Recepção">
                                  <p>Av. Francisco Matarazzo, 455</p>
                                  <p>Pav. 11, Água Branca</p>
                                  <p>São Paulo/SP</p>
                                </ContactCard> */
                }

                <
                ContactCard iconName = "contact"
                title = "Endereço" >
                <
                p >
                Avenida Marquês de São Vicente, nº 446(ANTIGO 10), Conjunto 1816 - 18 º andar <
                /p> <
                p > (Torre Comercial New Worker Tower) Barra Funda < /p> <
                p > CEP: 01139 - 000– São Paulo / SP < /p> < /
                ContactCard > <
                />
            )
        }

        {
            isMobile && ( <
                >
                <
                ContactCard iconName = "phone"
                title = "Telefone" >
                <
                p > +55(11) 3864 - 0800 < /p> <
                p > De segunda a sexta das 9 h às 17 h < /p> < /
                ContactCard >

                {
                    /* <ContactCard iconName="house" title="Recepção">
                                  <p>Av. Francisco Matarazzo, 455 Pav. 11, Água Branca</p>
                                  <p>São Paulo/SP</p>
                                </ContactCard> */
                }

                <
                ContactCard iconName = "contact"
                title = "Endereço" >
                <
                p >
                Avenida Marquês de São Vicente, nº 446(ANTIGO 10), Conjunto 1816 - 18 º andar <
                /p> <
                p > (Torre Comercial New Worker Tower) Barra Funda < /p> <
                p > CEP: 01139 - 000– São Paulo / SP < /p> < /
                ContactCard >

                <
                ContactCard iconName = "contact"
                title = "Notícias ABQM" >
                <
                p > Fique por dentro de tudo que < /p> <
                p > acontece na ABQM < /p> <
                a className = "link-wpp"
                href = "https://chat.whatsapp.com/HPqSZeaOUaJKV4cO4Ws8BX"
                target = "_blank"
                rel = "noopener noreferrer" >
                Clique aqui <
                /a> <
                p > e entre para o nosso < /p> <
                p > grupo de notícias < /p> < /
                ContactCard > <
                />
            )
        } <
        /div>

        <
        div className = "socials" >
        <
        Socials type = "footer"
        isDark / >
        <
        /div>

        <
        p className = "articleLaw" >
        <
        img alt = "Logo ABQM"
        className = "abqm-logo"
        src = { require('@abqm/theme/assets/images/icones/shieldWhiteFooter.svg') }
        />
        Nos termos dos artigos 18 e 20, da Lei Geral de Proteção de Dados(LGPD), n° 13.709 / 18, a ABQM disponibiliza aos quartistas e associados <
        br / >
        um canal para o envio de solicitações, esclarecimentos de dúvidas e / ou reclamações: lgpd @abqm.net.br <
        /p>

        <
        p className = "copyright" >
        Associação Brasileira de Criadores de Cavalo Quarto de Milha - CNPJ: 44.465.466/0001-38 | Todos os direitos reservados <
        /p>

        <
        a target = "_blank"
        className = "developers"
        rel = "noopener noreferrer"
        href = "https://www.iclouds.com.br/" >
        <
        p className = "text" > Designed and Developed by < /p>

        <
        img alt = "Logo iClouds"
        className = "iclouds-logo"
        src = { require('@abqm/theme/assets/images/logos/logo-iclouds.svg') }
        /> < /
        a > <
        /Container>
    );
}

Footer.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

const mapSizesToProps = ({ width }) => ({
    isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(Footer);