import { environment } from '@abqm/services';
import axios from 'axios';

const apiUrl = axios.create({
  baseURL:
    environment === 'production'
      ? 'https://intranet.abqm.net.br/ABQM.API/'
      : 'https://homolog.abqm.net.br/API/',
});

const centralUrl =
  environment === 'production'
    ? 'https://centraldoquartista.abqm.net.br'
    : 'http://centraldoquartista.abqm.iclouds.com.br';

export { apiUrl, centralUrl };
