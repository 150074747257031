import { urls } from '@abqm/services';

export default [
  {
    id: 1,
    name: 'Institucional',
    target: '_self',
    link: `${urls.portalURL}/institucional/`,
  },

  {
    id: 3,
    name: 'Quarto de Milha',
    target: '_self',
    link: `${urls.portalURL}/quarto-de-milha/`,
  },
  {
    id: 10,
    name: 'Stud Book',
    target: '_self',
    link: `${urls.portalURL}/stud-book/`,
  },
  {
    id: 8,
    name: 'Impressos',
    target: '_self',
    link: `${urls.portalURL}/impressos/`,
  },
  {
    id: 5,
    name: 'Esportes',
    target: '_self',
    link: `${urls.portalURL}/esportes/`,
  },
  {
    id: 4,
    name: 'Ranking',
    target: '_self',
    link: 'http://seqm.com.br/ranking/geral',
  },
  {
    id: 6,
    name: 'Hall da Fama',
    target: '_blank',
    link: 'https://abqm.net.br/halldafama/',
  },
  {
    id: 7,
    name: 'Awards',
    target: '_blank',
    link: 'https://abqm.net.br/awards/',
  },
  {
    id: 9,
    name: 'Equoterapia',
    target: '_self',
    link: `${urls.portalURL}/equoterapia/15258`,
  },
  {
    id: 11,
    name: 'Canal de ética',
    target: '_blank',
    link: `${urls.portalURL}/servicos/canal-de-etica`,
  },
  {
    id: 992,
    name: 'Equitação Western',
    target: '_self',
    link: `${urls.portalURL}/westernequitacao/`,
  },
];
