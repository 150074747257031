import React, { useEffect, useState } from 'react';

// import api from '../../services/api';
// import api from '@abqm/services';
import { urls, api, indexes } from '@abqm/services';
//import { handleScrollTop, formatDate } from '../../../../functions';
import { handleScrollTop, formatDate } from '@abqm/functions';

import { Container, News } from './styles';

import { MoreNews } from '../../components';

function LastedNews({ hotsite, conteudoNews }) {
  const [lastedNews, setLastedMoreNews] = useState([]);
  const [styleTextos, setStyleTextos] = useState({});
  const [styleNoticias, setStyleNoticias] = useState({});
  const [styleBotao, setStyleBotao] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 3,
            index_id: conteudoNews.id,
            getCover: true,
          },
        });

        setLastedMoreNews(data.rows);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [conteudoNews]);

  useEffect(() => {
    try {
      setStyleTextos({ color: `${hotsite[0].cor_transmissoes_textos}` });
      setStyleNoticias({ backgroundColor: `${hotsite[0].cor_noticias_fundo}`, color: `${hotsite[0].cor_noticias_textos}` });
      setStyleBotao({ backgroundColor: `${hotsite[0].cor_maisnoticias_botao}`, color: `${hotsite[0].cor_maisnoticias_texto}` });

      //border: solid 15px rgba(145, 102, 42, 0.3);

    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [hotsite]);

  return (
    <Container>
      <h1 style={styleTextos}>ÚLTIMAS NOTÍCIAS</h1>

      <section>
        {lastedNews.map(item => (
          <News
            to={{
              pathname: `/noticias/${item.url}`,
              idLocation: item.id,
            }}
            onClick={handleScrollTop}
            key={item.id}
            style={styleNoticias}
          >
            <img
              //src={`https://antigo.abqm.net.br/img/content_files/${item?.cover?.file}`}
              src={`${urls.hotsitesImageURL}/${item?.cover?.file}`}
              alt={item?.cover?.file}
            />

            <div>
              <span>{formatDate(item.date)}</span>
              <p>{item.title}</p>
            </div>
          </News>
        ))}
      </section>
      <MoreNews color={'#91662A'} hotsite={hotsite} conteudoNews={conteudoNews} />
    </Container>
  );
}

export default LastedNews;
