import { urls } from '@abqm/services';

export default [
  {
    id: 1,
    title: 'Institucional',
    url: `${urls.portalURL}/institucional`,
  },
  {
    id: 3,
    title: 'Quarto de Milha',
    url: `${urls.portalURL}/quarto-de-milha`,
  },
  {
    id: 10,
    title: 'Stud Book',
    url: `${urls.portalURL}/stud-book`,
  },

  {
    id: 8,
    title: 'Impressos',
    url: `${urls.portalURL}/impressos`,
  },

  {
    id: 5,
    title: 'Esportes',
    url: `${urls.portalURL}/esportes`,
  },
  {
    id: 4,
    title: 'Ranking',
    url: 'https://portal.seqm.com.br/ranking/geral',
  },
  {
    id: 6,
    title: 'Hall da Fama',
    url: 'https://abqm.net.br/halldafama/',
  },
  {
    id: 7,
    title: 'Awards',
    url: 'https://abqm.net.br/awards/',
  },
  {
    id: 9,
    title: 'Equoterapia',
    url: `${urls.portalURL}/equoterapia`,
  },
  {
    id: 11,
    title: 'Canal de ética',
    url: 'https://abqm.net.br/abqm_site/pt/canal-etica-e-ouvidoria',
  },
];
