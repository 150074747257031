import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import axios from 'axios';

import MenuMobile from '../MenuMobile';
import HeaderTopbar from '../HeaderTopbar';
import HeaderMobile from '../HeaderMobile';

import { Container, CloseButton, Icon, ChildrenWrap } from './styles';

import { urls } from '@abqm/services';

function MenuRoot({ children, isMobile, emptyList, isChannels, type }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchVisibility, setSearchVisibility] = useState(false);
  const [headerParams, setHeaderParams] = useState('');
  const [userName, setUserName] = useState(null);
  const [validateCookieLoading, setValidateCookieLoading] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([]);

  const COOKIE_URL = urls.cookieURL;

  const onSetCookies = useCallback(
    ({ name, token, expireDate }) => {
      setCookie(name, token, {
        path: '/',
        domain: COOKIE_URL,
        ...(expireDate ? { expires: expireDate } : {}),
      });
    },
    [setCookie]
  );

  const onRemoveCookies = useCallback(
    cookieName => {
      removeCookie(cookieName, {
        path: '/',
        domain: COOKIE_URL,
      });
    },
    [removeCookie]
  );

  const handleLogout = useCallback(async () => {
    await axios.delete(
      'https://intranet.abqm.net.br/ABQM.API/api/ServicosOnline/TokenExp/DeletarToken',
      {
        params: { token: cookies['ServicosOnline[tokenABQM]'] },
      }
    );
    onRemoveCookies('ServicosOnline[tokenABQM]');
    window.location = window.location.pathname;
  }, [cookies, onRemoveCookies]);

  useEffect(() => {
    async function validateToken() {
      try {
        setValidateCookieLoading(true);

        const params = queryString.parse(window.location.search);

        let token = '';

        if (params.token) {
          token = params.token;
          onRemoveCookies('ServicosOnline[tokenABQM]');
          onSetCookies({ name: 'ServicosOnline[tokenABQM]', token });
        } else if (cookies['ServicosOnline[tokenABQM]']) {
          token = cookies['ServicosOnline[tokenABQM]'];
        }

        if (token === '') {
          onRemoveCookies('ServicosOnline[tokenABQM]');
          return;
        }

        const { data } = await axios.get(
          'https://intranet.abqm.net.br/ABQM.API/api/Site/Pessoa/PessoaSelectPorToken',
          {
            params: { token },
          }
        );

        if (data.msg) {
          token = '';
          onRemoveCookies('ServicosOnline[tokenABQM]');
        } else {
          setUserName(data.listDto.nome_pessoa);
          setHeaderParams(token ? `token=${token}` : '');
        }
      } catch (error) {
        onRemoveCookies('ServicosOnline[tokenABQM]');
      } finally {
        setValidateCookieLoading(false);
      }
    }

    validateToken();
  }, [cookies, onRemoveCookies, onSetCookies]);

  function toggleSearch() {
    setSearchVisibility(!searchVisibility);
  }

  return (
    <Container id="outer-container" isChannels={isChannels}>
      {isMobile && (
        <>
          <MenuMobile
            observatory={type === 'observatory'}
            setIsMenuOpen={setIsMenuOpen}
            isMenuOpen={isMenuOpen}
            emptyList={emptyList}
            userName={userName}
            validateCookieLoading={validateCookieLoading}
            onLogout={handleLogout}
          />

          <CloseButton
            disabled={!isMenuOpen}
            onClick={() => setIsMenuOpen(false)}
          >
            <Icon src={require(`@abqm/theme/assets/images/icones/close.svg`)} />
          </CloseButton>
        </>
      )}
      <main id="page-wrap">
        {!isMobile ? (
          <HeaderTopbar
            hasCenter={!isChannels}
            headerParams={headerParams}
            userName={userName}
            validateCookieLoading={validateCookieLoading}
            onLogout={handleLogout}
          />
        ) : (
          <HeaderMobile
            isMenuOpen={isMenuOpen}
            isChannels={isChannels}
            searchVisibility={searchVisibility}
            toggleSearch={toggleSearch}
            type={type}
          />
        )}

        <ChildrenWrap searchVisible={searchVisibility} type={type}>
          {children}
        </ChildrenWrap>
      </main>
    </Container>
  );
}

MenuRoot.defaultProps = {
  emptyList: false,
  isChannels: false,
  type: 'default',
};

MenuRoot.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isMobile: PropTypes.bool.isRequired,
  emptyList: PropTypes.bool,
  isChannels: PropTypes.bool,
  type: PropTypes.string,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 1100,
});

export default withSizes(mapSizesToProps)(MenuRoot);
