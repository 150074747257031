import { urls } from '@abqm/services';

export default [
  {
    title: 'Canais ABQM',
    url: urls.channelURL,
    icon: 'icone_canais_menu.svg',
    favorite: true,
    featured: true,
    useToken: false,
  },
  {
    title: 'Consulta de Animais',
    url: `https://consulta.abqm.net.br/`,
    icon: 'consulta_de_animais.svg',
    favorite: false,
    featured: false,
  },
  {
    title: 'SEQM',
    url: 'https://seqm.com.br?',
    icon: 'channel_sistema_de_esportes.svg',
    favorite: false,
    featured: true,
    useToken: true,
  },
  {
    title: 'Central do Quartista',
    url: 'https://centraldoquartista.abqm.net.br',
    icon: 'channel_studbook.svg',
    favorite: false,
    featured: true,
    useToken: true,
  },
  {
    title: window.innerWidth <= 1025 ? 'Revista QM' : 'Revista Quarto de Milha',
    url: urls.magazineURL,
    icon: 'channel_revista.svg',
    favorite: false,
    featured: false,
  },
  {
    title: window.innerWidth <= 1025 ? 'QM Play' : 'Quarto de Milha Play',
    url: urls.playURL,
    icon: 'abqm_play.svg',
    favorite: false,
    featured: false,
  },
  {
    title: 'Calendário 2024',
    url: urls.portalURL + '/esportes/eventos-oficiais-2024',
    icon: 'calendario_2020.svg',
    favorite: false,
    featured: false,
  },
  {
    title: window.innerWidth <= 1025 ? 'Boletins ABQM' : 'Boletins ABQM',
    url: urls.observatoryURL,
    icon: 'observatorio_do_cavalo.svg',
    favorite: false,
    featured: false,
  },
  {
    title: 'Sala de Imprensa',
    url: urls.imprensaUrl,
    icon: 'icon-sala-imprensa.png',
    favorite: false,
    featured: false,
  },
];
