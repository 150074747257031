import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FiLogOut } from 'react-icons/fi';
import { useCookies } from 'react-cookie';

import { urls } from '@abqm/services';

import Button from '../Button';

import { Container, AuthOptions, LogoutButton } from './styles';

export default function HeaderTopbar({
  hasCenter,
  // eslint-disable-next-line no-unused-vars
  hasRight,
  headerParams,
  userName,
  validateCookieLoading,
  onLogout,
}) {
  const [cookies] = useCookies([]);
  const token = cookies['ServicosOnline[tokenABQM]'];

  const [dropdownAuthVisible, setDropdownAuthVisible] = useState(false);

  const handleClickAuthButton = useCallback(() => {
    if (userName) setDropdownAuthVisible(prev => !prev);
    else window.open(`${urls.portalURL}/login`, '_self');
  }, [userName]);

  return (<
            Container >
    <
            div className="left" >
      {
        token ? (
          <
            Button title="Contato"
            icon="contato"
            isPrimary href={`${urls.portalURL}/servicos/atendimento-inteligente`}
          //isPrimary href={`https://intranet.abqm.net.br/Comercial/ServicosOnline/UI/AtendimentoInteligente/AtendInteligente.aspx?faq=1&token=${token}`}
          />
        ) : (
          <
            Button title="Contato"
            icon="contato"
            isPrimary href={`${urls.portalURL}/servicos/atendimento-inteligente`}
          //isPrimary href={`https://intranet.abqm.net.br/Comercial/ServicosOnline/UI/AtendimentoInteligente/AtendInteligente.aspx?faq=1`}
          />
        )
      }


      <
        Button title="Associe-se"
        href={`${urls.portalURL}/servicos/associe-se`}
      />

      {
        token ? (<
          Button title="Pagamentos"
          href={`https://centraldoquartista.abqm.net.br/pagamentos?token=${token}`}
        />
        ) : (<
          Button title="Pagamentos"
          href="https://abqm.net.br/login/pagamentos" /
        >
        )
      }

      <
        Button title="2ª via boleto"
        href="https://abqm.net.br/php/pt/modal?boleto=true" /
      >
      <
            /div>

      <
            div className="center" > {
          hasCenter && ( <
                    >
            <
              Button title="Portal ABQM"
              isPrimary isBigger href={`https://abqm.net.br?${headerParams}`}
            />

            <
              Button title="SEQM"
              isBigger href={`https://seqm.com.br?${headerParams}`}
            />

            <
              Button title="Central do Quartista"
              isBigger href={
                token ?
                  `https://centraldoquartista.abqm.net.br/auth?token=${token}` : `https://centraldoquartista.abqm.net.br`
              }
            /> < />
            )
            } <
            /div>

            <
            div className="right" >
              <
                Button title={userName || 'Entrar | Cadastro'}
                icon="user"
                rightIcon={userName && 'chevron-down'}
                isPrimary isLoading={validateCookieLoading}
                onClick={handleClickAuthButton}
              /> {
                dropdownAuthVisible && (<
                AuthOptions >
                  <
                LogoutButton onClick={onLogout} >
                    <
                div >
                      <
                span > Sair < /span> < /
                div > <
                          FiLogOut size={12}
                          color="#fff" />
                        <
                /LogoutButton> < /
                AuthOptions >
                        )
        } <
        /div> < /
    Container >
                        );
}

                        HeaderTopbar.defaultProps = {
                          hasCenter: false,
                        hasRight: false,
                        headerParams: false,
                        userName: false,
                        validateCookieLoading: false,
                        onLogout: false,
};

                        HeaderTopbar.propTypes = {
                          hasCenter: PropTypes.bool,
                        hasRight: PropTypes.bool,
                        headerParams: PropTypes.string,
                        userName: PropTypes.string,
                        validateCookieLoading: PropTypes.bool,
                        onLogout: PropTypes.func,
};
