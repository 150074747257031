import { urls } from '@abqm/services';

export default [
  {
    id: 745,
    name: 'Institucional',
    target: '_self',
    link: `${urls.observatoryURL}/institucional`,
    subItems: [
      {
        id: '1',
        name: 'Quem Somos',
        link: `${urls.observatoryURL}/institucional/quem-somos`,
      },
      {
        id: '2',
        name: 'Associados',
        link: `${urls.observatoryURL}/institucional/associados`,
      },
      {
        id: '3',
        name: 'Modalidades',
        link: `${urls.observatoryURL}/institucional/modalidades`,
      },
      {
        id: '4',
        name: 'Perguntas Frequentes',
        link: `${urls.observatoryURL}/institucional/perguntas-frequentes`,
      },
    ],
  },
  {
    id: 746,
    name: 'Infográfico',
    target: '_self',
    link: `${urls.observatoryURL}/infografico`,
    subItems: [
      {
        id: '1',
        name: 'Stud Book',
        link: `${urls.observatoryURL}/infografico/stud-book-15053`,
      },
      {
        id: '2',
        name: 'Esportes',
        link: `${urls.observatoryURL}/infografico/esportes-15054`,
      },
      {
        id: '3',
        name: 'Núcleos e Associações',
        link: `${urls.observatoryURL}/infografico/nucleos-e-associacoes`,
      },
    ],
  },
  {
    id: 747,
    name: 'Legislação',
    target: '_self',
    link: `${urls.observatoryURL}/legislacao`,
  },
  {
    id: 748,
    name: 'Publicações',
    target: '_self',
    link: `${urls.observatoryURL}/publicacoes`,
  },
  {
    id: 749,
    name: 'Campeonatos',
    target: '_self',
    link: `${urls.observatoryURL}/campeonatos`,
    subItems: [
      {
        id: '1',
        name: 'Eventos Oficiais',
        link: `https://www.seqm.com.br/`,
      },
      {
        id: '2',
        name: 'Ranking',
        link: `https://www.seqm.com.br/ranking/geral`,
      },
      {
        id: '3',
        name: 'Resultados',
        link: `https://www.seqm.com.br/resultados`,
      },
    ],
  },
  {
    id: 750,
    name: 'Editais',
    target: '_self',
    link: `${urls.observatoryURL}/editais`,
  },
  {
    id: 751,
    name: 'Imprensa',
    target: '_self',
    link: `${urls.observatoryURL}/imprensa`,
  },
  {
    id: 752,
    name: 'Contato',
    target: 'blank',
    link: `https://abqm.net.br/abqm_site/pt/contacts/attendance/`,
  },
];
