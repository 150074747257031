/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import {
  Container,
  ButtonWrapper,
  ButtonBackground,
  Button,
  Icon,
  ButtonTitle,
} from './styles';

import data from './data';

import { urls } from '@abqm/services';

export default function ChannelsWrapper() {
  const [cookies, setCookie] = useCookies([]);

  const [queryParams, setQueryParams] = useState('');

  const params = queryString.parse(window.location.search);
  const token = cookies['ServicosOnline[tokenABQM]'];

  const COOKIE_URL = urls.cookieURL;

  const onSetCookies = useCallback(
    ({ name, token: value, expireDate }) => {
      setCookie(name, value, {
        path: '',
        domain: COOKIE_URL,
        ...(expireDate ? { expires: expireDate } : {}),
      });
    },
    [setCookie]
  );

  useEffect(() => {
    function validateToken() {
      if (params.token) {
        setQueryParams(`token=${params.token}`);
        onSetCookies({
          name: 'ServicosOnline[tokenABQM]',
          token: params.token,
        });
      } else if (cookies['ServicosOnline[tokenABQM]']) {
        setQueryParams(`token=${cookies['ServicosOnline[tokenABQM]']}`);
      }
    }

    if (params.token || cookies['ServicosOnline[tokenABQM]']) validateToken();
  }, [cookies, onSetCookies, params.token]);

  return (
    <Container
      style={{
        scrollBehavior: 'smooth',
        scrollSnapType: 'x mandatory',
      }}
    >
      {data.map((channel, index) => (
        <ButtonWrapper
          style={{
            scrollSnapAlign:
              (index === 0 || index === data.length - 1) && 'center',
          }}
        >
          <ButtonBackground>
            <Button
              href={
                token && index === 3
                  ? `https://centraldoquartista.abqm.net.br/auth?token=${token}`
                  : channel.useToken
                    ? `${channel.url}${queryParams}`
                    : `${channel.url}`
              }
            >
              <Icon
                className="Icon"
                src={require(`@abqm/theme/assets/images/icones/${channel.icon}`)}
              />
            </Button>
          </ButtonBackground>
          <ButtonTitle>{channel.title}</ButtonTitle>
        </ButtonWrapper>
      ))}
    </Container>
  );
}

ChannelsWrapper.defaultProps = {};

ChannelsWrapper.propTypes = {};
