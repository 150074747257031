/* eslint-disable no-restricted-globals */
var env = false;

try {
    env = require('../../.env.js').default;
} catch (error) {
    console.log('.envs is not loaded');
}

export default {
    imageURL: 'https://abqm.net.br/abqm_site/img/content_files/',
    adsURL: 'https://abqm.net.br/abqm_site/app/webroot/img/advertisements/',
    ...(env
        ? { ...env.urls }
        : {
            apiURL: 'https://api.abqm.net.br:3334',
            playURL: location.origin,
            portalURL: location.origin,
            searchURL: location.origin,
            channelURL: location.origin,
            magazineURL: location.origin,
            observatoryURL: location.origin,
            whatsAppShareURL: 'https://intranet.abqm.net.br/whatssapp_site/Noticias.aspx?Noticia=',
        }),
};
