import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import Modal from 'react-modal';

import { urls } from '@abqm/services';

import ListItems from './ListItems';

import {
  Icon,
  Line,
  Menu,
  Button,
  Socials,
  Contact,
  Container,
  MenuFooter,
  WrapperList,
  WrapperLine,
  ButtonsWrapper,
  ModalContainer,
  CloseButton,
} from './styles';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.9)',
    zIndex: 9999,
  },
};

const IconSocial = ({ iconName, link }) => {
  function renderIcon() {
    return {
      instagram: (
        <img
          alt="Instagram"
          src={require('@abqm/theme/assets/images/icones/instagram-mobile.svg')}
        />
      ),
      facebook: (
        <img
          alt="Facebook"
          src={require('@abqm/theme/assets/images/icones/facebook-mobile.svg')}
        />
      ),
      youtube: (
        <img
          alt="Youtube"
          src={require('@abqm/theme/assets/images/icones/youtube-mobile.svg')}
        />
      ),
    };
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {renderIcon()[iconName]}
    </a>
  );
};

export default function MenuMobile({
  setIsMenuOpen,
  isMenuOpen,
  emptyList,
  observatory,
  userName,
  validateCookieLoading,
  onLogout,
}) {
  const [cookies] = useCookies([]);

  const token = cookies['ServicosOnline[tokenABQM]'];
  const [showModal, setShowModal] = useState(false);

  return (
    <Container onClick={() => setIsMenuOpen(true)}>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={modalStyle}
      >
        <ModalContainer>
          <CloseButton onClick={() => setShowModal(false)}>
            <Icon src={require(`@abqm/theme/assets/images/icones/close.svg`)} />
          </CloseButton>
          <h2>Pagamentos Online</h2>
          <p>
            Para acessar esta funcionalidade, favor baixar o aplicativo&nbsp;
            <span>ABQM Oficial</span>.
          </p>
          <p>Aproveite todas as facilidades disponíveis!</p>
          <div id="install-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/br/app/abqm-oficial/id1084358861"
            >
              <img
                className="icon"
                alt="App Store"
                src={require('@abqm/theme/assets/images/badges/app_store.svg')}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=br.com.abqm.abqm&hl=pt_BR"
            >
              <img
                className="icon"
                alt="App Store"
                src={require('@abqm/theme/assets/images/badges/play_store.svg')}
              />
            </a>
          </div>
        </ModalContainer>
      </Modal>
      <Menu
        disableOverlayClick
        pageWrapId="page-wrap"
        isOpen={isMenuOpen}
        customBurgerIcon={
          <Icon src={require(`@abqm/theme/assets/images/icones/menu.svg`)} />
        }
      >
        <ButtonsWrapper>
          <Button
            title="Associe-se"
            href="https://intranet.abqm.net.br/Comercial/ServicosOnline/UI/CadastroPessoa/associe-se.aspx"
          />

          {token ? (
            <Button title="Pagamentos" onClick={() => setShowModal(true)} />
          ) : (
            <Button title="Pagamentos" onClick={() => setShowModal(true)} />
          )}

          <Button
            title="2ª via boleto"
            href="https://abqm.net.br/php/pt/modal?boleto=true"
          />
        </ButtonsWrapper>

        <WrapperLine>
          <Line />
        </WrapperLine>

        <WrapperList>
          <ListItems observatory={observatory} emptyList={emptyList} />
        </WrapperList>

        <MenuFooter>
          <Button
            style={{ width: 'auto', padding: 16, marginBottom: 16 }}
            title={
              userName
                ? `${userName.length > 24
                  ? `${userName.slice(0, 24)}...`
                  : userName
                } | Sair`
                : 'Entrar | Cadastro'
            }
            icon="user"
            // rightIcon={userName && 'chevron-down'}
            isPrimary
            isLoading={validateCookieLoading}
            onClick={
              userName
                ? onLogout
                : () => window.open(`${urls.portalURL}/login`, '_self')
            }
          />
          <Contact
            title="Contato"
            isPrimary
            href="https://abqm.net.br/abqm_site/pt/contacts/attendance/"
          />
          <Socials>
            <div className="socials">
              <IconSocial
                iconName="youtube"
                link="https://www.youtube.com/channel/UCI7n-NUxB84gx-A6MgD4YJA"
              />

              <IconSocial
                iconName="instagram"
                link="https://www.instagram.com/p/B7j4To7juEA/"
              />

              <IconSocial
                iconName="facebook"
                link="https://www.facebook.com/abqmoficial/"
              />
            </div>
          </Socials>
          <Socials>
            <div className="socials">
              <a
                className="twitter"
                href="https://twitter.com/abqm_oficial?lang=pt"
              >
                @abqmoficial
              </a>
            </div>
          </Socials>
        </MenuFooter>
      </Menu>
    </Container>
  );
}

IconSocial.propTypes = {
  link: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

MenuMobile.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  emptyList: PropTypes.bool.isRequired,
  observatory: PropTypes.bool,
  userName: PropTypes.string,
  validateCookieLoading: PropTypes.bool,
  onLogout: PropTypes.func,
};

MenuMobile.defaultProps = {
  observatory: false,
  userName: false,
  validateCookieLoading: false,
  onLogout: false,
};
