import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { urls, api, indexes } from '@abqm/services';

import { Container } from './styles';

export default function MenuItems({ hotsite, conteudoNews }) {
  const [style, setStyle] = useState({});
  const [noticiaUrl, setNoticiaUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStyle({ border: 'none', color: `${hotsite[0].cor_menu_textos}`, backgroundColor: `${hotsite[0].cor_menu_botoes}` });

        const { data } = await api.get('contents', {
          params: {
            page: 1,
            pagesize: 3,
            index_id: conteudoNews.id,
            getCover: true,
          },
        });

        setNoticiaUrl(data.rows[0].url);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchData();
  }, [hotsite, conteudoNews]);

  return (
    <Container>
      {hotsite.map(item => (
        <>
          <Link to="/" className="isMobile style" style={style}>
            <p>Home</p>
          </Link>
          <Link to={`/provas/${item.url}`} style={style}>
            <p> Provas </p>
          </Link>
          <Link to={`/noticias/${noticiaUrl}`} style={style}>
            <p>Notícias</p>
          </Link>
          <Link to="/sala-de-imprensa" style={style}>
            <p> Sala de Imprensa </p>
          </Link>
          <Link to={`/campeoes/${item.url}`} style={style}>
            <p> Campeões </p>
          </Link>
          <a href="https://www.abqm.net.br/" target='_blank' style={style}>
            <p> Portal ABQM </p>
          </a>
        </>
      ))
      }
    </Container>
  );
}
