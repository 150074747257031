/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Logo,
  Container,
  LogoWrapper,
  MenuWrapper,
  SearchInput,
  SearchWrapper,
  IconWrapper,
  IconLink,
  IconHome,
  IconContainer,
  HeaderContent,
} from './styles';

export default function HeaderMobile({
  isMenuOpen,
  isChannels,
  toggleSearch,
  searchVisibility,
  type,
}) {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    function handleMenuState() {
      if (isMenuOpen) {
        return setHide(true);
      }

      return setTimeout(() => {
        setHide(false);
      }, 500);
    }

    handleMenuState();
  }, [isMenuOpen]);

  const logoPath = {
    play: require(`@abqm/theme/assets/images/icones/play_logo.svg`),
    magazine: require(`@abqm/theme/assets/images/logos/logo-revista.svg`),
    observatory: require(`@abqm/theme/assets/images/icones/icone-observatorio.svg`),
  };

  function renderDefault() {
    return (
      <Container hide={hide}>
        <MenuWrapper type={type} show>
          <LogoWrapper isChannels={isChannels}>
            <a href="/">
              <Logo
                src={
                  type === 'observatory'
                    ? logoPath[type]
                    : require(`@abqm/theme/assets/images/logos/logo_quarto_de_milha.svg`)
                }
              />
            </a>
          </LogoWrapper>

          <IconWrapper open={searchVisibility} onClick={() => toggleSearch()}>
            <IconContainer
              src={
                searchVisibility
                  ? require(`@abqm/theme/assets/images/icones/close_search.svg`)
                  : require(`@abqm/theme/assets/images/icones/open_search.svg`)
              }
            />
          </IconWrapper>
        </MenuWrapper>

        <HeaderContent visible={searchVisibility}>
          <SearchWrapper isChannels={isChannels}>
            <SearchInput />
          </SearchWrapper>
        </HeaderContent>
      </Container>
    );
  }

  function renderExternal() {
    return (
      <Container type={type}>
        <MenuWrapper type={type} show>
          <LogoWrapper external>
            <Logo type={type} src={logoPath[type]} />
          </LogoWrapper>

          <IconLink href="https://abqm.net.br/">
            <IconHome
              src={require(`@abqm/theme/assets/images/icones/icone_casa.svg`)}
            />
          </IconLink>
        </MenuWrapper>
      </Container>
    );
  }

  const renderers = {
    default: renderDefault,
    play: renderExternal,
    magazine: renderExternal,
    observatory: renderDefault,
  };

  return <>{renderers[type]()}</>;
}

HeaderMobile.defaultProps = {
  type: 'default',
};

HeaderMobile.propTypes = {
  type: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
};
