import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { urls } from '@abqm/services';

import MenuArrowSvg from '@abqm/theme/assets/images/icones/menu_arrow.svg';
import items from './data';
// import itemsInst from './dataInst';
import itemsObs from './dataObs';
import { Container, SearchInput, MenuArrow, Center, Socials } from './styles';

export default function Header({
  isWithContents,
  internal,
  type,
  image,
  ...rest
}) {
  function renderDefault() {
    return (
      <Container isWithContents={isWithContents} {...rest}>
        <div className="content">
          {isWithContents && (
            <div className="left">
              <Link to="/">
                <img
                  alt="ABQM Logo"
                  className="logo-abqm"
                  src={require('@abqm/theme/assets/images/icones/logo-quartodemilha.svg')}
                />
              </Link>

              {internal && (
                <div className="browse">
                  <a href={urls.portalURL} className="link">
                    <img
                      src={require('@abqm/theme/assets/images/icones/icone_casa.svg')}
                      alt="Icone Home"
                      className="icon"
                    />
                    Home
                  </a>
                </div>
              )}

              {/* <div className="browse">
                <span>Acompanhe aqui</span>

                <a


                  href="http://abqm.net.br/campeonatonacional/"
                >
                  <img
                    alt="Evento Logo"
                    className="logo-event"
                    src={require('@abqm/theme/assets/images/icones/logo-campeonatonacional.svg')}
                  />
                </a>
              </div> */}
            </div>
          )}

          <Center isWithContents={isWithContents}>
            <SearchInput />
          </Center>

          <div className="right">
            {/* {isWithContents && (
              <div className="animal-consult">
                <a

                  className="content"

                  href="https://servicosonline.abqm.net.br/animais"
                >
                  <img
                    alt="Horse Icon"
                    className="horse"
                    src={require('@abqm/theme/assets/images/icones/logo-consultadeanimais.svg')}
                  />

                  <h4>Consulta</h4>
                  <span>de animais</span>
                </a>
              </div>
            )} */}

            <Socials place="mobile" />
          </div>

          {isWithContents && (
            <ul className="menu-home">
              {items.map(item => (
                <li key={item.id} className="item">
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Container>
    );
  }

  function renderObservatory() {
    return (
      <Container observatory isWithContents {...rest}>
        <div className="content">
          <div className="left">
            <Link to="/">
              <img
                alt="ABQM Logo"
                className="logo-abqm"
                src={require('@abqm/theme/assets/images/icones/logo-quartodemilha.svg')}
              />
            </Link>

            <div className="observatory-title">
              <p>Observatório do Cavalo</p>
            </div>

            <div className="browse">
              {internal && (
                <a href={urls.observatoryURL} className="link">
                  <img
                    src={require('@abqm/theme/assets/images/icones/icone_casa.svg')}
                    alt="Icone Home"
                    className="icon"
                  />
                  Home
                </a>
              )}

              <a href={urls.channelURL} className="link">
                <img
                  src={require('@abqm/theme/assets/images/icones/icone_canais.svg')}
                  alt="Icone Canais"
                  className="icon"
                />
                Canais
              </a>
            </div>
          </div>

          <div className="right">
            <SearchInput internal />
          </div>
        </div>

        <ul className="menu">
          {itemsObs.map(item => (
            <>
              <li key={item.id} className="item">
                <a href={item.link}>{item.name}</a>
                {item.subItems && (
                  <>
                    <MenuArrow src={MenuArrowSvg} />
                    <ul className="subMenu">
                      {item.subItems.map(subItem => (
                        <li key={subItem.id} className="subItem">
                          <a href={subItem.link}>{subItem.name}</a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            </>
          ))}
        </ul>
      </Container>
    );
  }

  function renderInternal() {
    return (
      <Container internal type={type}>
        <div className="content">
          <div className="left">
            <a href={urls.portalURL}>
              <img
                alt="ABQM Logo"
                className="logo-abqm"
                src={require('@abqm/theme/assets/images/icones/logo-quartodemilha.svg')}
              />
            </a>

            <div className="browse">
              <a href={urls.portalURL} className="link">
                <img
                  src={require('@abqm/theme/assets/images/icones/icone_casa.svg')}
                  alt="Icone Casa"
                  className="icon"
                />
                Home
              </a>

              <a href={urls.channelURL} className="link">
                <img
                  src={require('@abqm/theme/assets/images/icones/icone_canais.svg')}
                  alt="Icone Canais"
                  className="icon"
                />
                Canais
              </a>
            </div>

            <ul className="menu">
              {items.map(item => (
                <li key={item.id} className="item">
                  <a href={item.link} target={item.target}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="right">
            <SearchInput internal />
          </div>
        </div>
      </Container>
    );
  }

  function renderExternal() {
    return (
      <Container type="external" magazine={type === 'magazine'}>
        <div className="content" type={type}>
          <div className="browse">
            <a href={urls.portalURL} className="link">
              <img
                src={require('@abqm/theme/assets/images/icones/icone_casa.svg')}
                alt="Icone Casa"
                className="icon"
              />
              Home
            </a>

            <a href={urls.channelURL} className="link">
              <img
                src={require('@abqm/theme/assets/images/icones/icone_canais.svg')}
                alt="Icone Canais"
                className="icon"
              />
              Canais
            </a>
          </div>

          <div className="logo">
            <img src={image} alt="Page Logo" />
          </div>
        </div>
      </Container>
    );
  }

  const renders = {
    default: renderDefault(),
    observatory: renderObservatory(),
    internal: renderInternal(),
    services: renderInternal(),
    external: renderExternal(),
    magazine: renderExternal(),
  };

  return <>{renders[type]}</>;
}

Header.defaultProps = {
  isWithContents: false,
  internal: false,
  type: 'default',
  image: '',
};

Header.propTypes = {
  isWithContents: PropTypes.bool,
  internal: PropTypes.bool,
  type: PropTypes.string,
  image: PropTypes.string,
};
